exports.components = {
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-blog-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/blog.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-blog-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-dataset-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/dataset.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-dataset-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-investigation-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/investigation.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-investigation-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-lostfound-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/lostfound.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-lostfound-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-missing-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/missing.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-missing-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-page-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/page.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-page-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-404-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/404.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-404-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-about-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/about.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-about-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-blog-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/blog.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-blog-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-data-archive-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/data-archive.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-data-archive-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-incident-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/incident.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-incident-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-index-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/index.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-index-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-investigations-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/investigations.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-investigations-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-lost-found-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/lost-found.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-lost-found-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-observation-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/observation.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-observation-js" */),
  "component---src-pages-datasets-chemical-weapons-ghouta-js": () => import("./../../../src/pages/datasets/chemical-weapons/ghouta.js" /* webpackChunkName: "component---src-pages-datasets-chemical-weapons-ghouta-js" */),
  "component---src-pages-datasets-chemical-weapons-js": () => import("./../../../src/pages/datasets/chemical-weapons.js" /* webpackChunkName: "component---src-pages-datasets-chemical-weapons-js" */),
  "component---src-pages-memory-js": () => import("./../../../src/pages/memory.js" /* webpackChunkName: "component---src-pages-memory-js" */)
}

